//== Typography Variable
$font-family-base: 'Roboto', sans-serif;
$font-family-heading: 'Roboto', sans-serif;
$font-font_awesome: 'FontAwesome';
$font-size-base: 14px;

// Site Typography Colors
$paragraph: #444444;
$paragraph-light: #666666;
$gray-color: #999999;
$light-gray: #f2f2f3;
$light-white: #f4f5f7;
$ash-color: #d2d2d2;

//== Brand Color
$theme-color: #dc4734;
$theme-color-dark: darken($theme-color, 15%);
$theme-color-light: lighten($theme-color, 15%);
$bdr-color: #dddddd;
$bg-dark: #22282e;
$bg-color: #f1f1f1;

//== Alternative Color
$white: #fff;
$offwhite: #f4f4f4;
$black: #000;
$black-soft: #333333;
$navy: #3b5998;
$pink: #FF69B4;
$violet: #9933cc;
$olive: #559137;
$green: #008000;
$red: #e74c3c;
$orange: #f5740a;
$yellow: #fbbf00;
$lebel-new: #3fcc8d;
$lebel-discount: #ff5e00;

//== Social Media Icons Color
//== Social Media Icons Color
$facebook: #3B5999;
$twitter: #1DA1F2;
$pinterest: #CB2028;
$youtube: #CC332D;
$google-plus: #fe6d4c;
$instagram: #c619b8;

//== Responsive Variables
$xlmax-device: 'only screen and (min-width: 1200px) and (max-width: 1599.98px)';
$xlmid-device: 'only screen and (min-width: 1200px) and (max-width: 1499.98px)';
$xxl-device: 'only screen and (min-width: 1600px)';
$xl-device: 'only screen and (min-width: 1200px)';
$lg-device: 'only screen and (min-width: 992px) and (max-width: 1199.98px)';
$md-device: 'only screen and (min-width: 768px) and (max-width: 991.98px)';
$sm-device: 'only screen and (max-width: 767.98px)';
$xs-device: 'only screen and (max-width: 575.98px)';
$xxs-device: 'only screen and (max-width: 479.98px)';