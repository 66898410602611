/*--------- friend request list start ---------*/
.frnd-request {
    &-list {
        background-color: $white;
        padding: 25px;
        width: 100%;
        display: inherit !important;
        left: 0;
        right: 0;
        position: absolute;
        z-index: 9;
        opacity: 0;
        visibility: hidden;
        transform: translateY(13px);
        transition: 0.4s;
        box-shadow: 0px 1px 15px 0px rgba(51, 51, 51, 0.2);
        &.show {
            opacity: 1;
            visibility: visible;
        }
        li {
            width: 100%;
            margin-bottom: 10px;
            padding-bottom: 10px;
            border-bottom: 1px solid #e5e5e5;
            &:last-child {
                margin-bottom: 0;
                padding-bottom: 0;
                border: none;
            }
        }
    }
    &-member {
        display: flex;
        align-items: center;
        .request-thumb {
            width: 80px;
            max-height: 80px;
        }
    }
}
.frnd-content {
    padding-left: 15px;
    width: calc(100% - 80px);
    p {
        font-size: 13px;
        color: $paragraph-light;
        padding-top: 6px;
    }
}
.request-btn-inner {
    margin-top: 5px;
    .frnd-btn {
        font-size: 13px;
        font-weight: 500;
        color: $white;
        text-transform: capitalize;
        line-height: 1;
        padding: 8px 20px;
        display: inline-block;
        border: 1px solid transparent;
        background-color: $theme-color;
        // responsive
        @media #{$xs-device} {
            padding: 8px 15px;
        }
        &.delete {
            color: $black-soft;
            border: 1px solid $black-soft;
            background-color: transparent;
            &:hover {
                color: $white;
            }
        }
        &:hover {
            background-color: $black-soft;
        }
    }
}
/*--------- friend request list end ---------*/