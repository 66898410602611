
/*------- short classes start -------*/
.bg-color {
    background-color: #f1f1f1;
}

.pt-20 {
    padding-top: 20px;
    // responsive
    @media #{$sm-device} {
        padding-top: 10px;
    }
}

.pt-30 {
    padding-top: 30px;
    // responsive
    @media #{$sm-device} {
        padding-top: 10px;
    }
}
.pt-80 {
    padding-top: 80px;
}

.mb-20 {
    margin-bottom: 20px;
    // responsive
    @media #{$sm-device} {
        margin-bottom: 10px;
    }
}
.mt-20 {
    margin-top: 20px;
    // responsive
    @media #{$sm-device} {
        margin-top: 10px;
    }
}

.mt--20 {
    margin-top: -20px;
}
.mt--30 {
    margin-top: -30px;
}

.plr-80 {
    padding: 0 80px;
}

/*------- short classes end -------*/